<template>
  <div>
    <b-card>
      <div slot="header">
        <strong>개인정보 유출내역 검색</strong>
      </div>
      <b-input-group class="mb-2">
        <b-input-group-prepend>
          <b-button variant="primary" @click="list">
            <i class="fa fa-search"></i> 검색
          </b-button>
        </b-input-group-prepend>
        <b-form-input type="text" placeholder="전화번호, 이메일, 이름, 아이디, 닉네임, 개인통관고유번호" v-model="form.list.q" @keypress.enter="list" v-focus></b-form-input>
      </b-input-group>
      <div class="clearfix">
        <b-button class="" variant="primary" @click="list">검색</b-button>
      </div>
    </b-card>
    <b-alert variant="warning" show>
      - 유출시각은 2022-03-14 새벽 1시로, 이후 가입한 고객은 유출내역이 없습니다.<br/>
      - 검색은 이름 외에는 정확히 일치해야 검색됩니다.<br/>
      - 이후 필터링은 부분 일치해도 필터링 됩니다.<br/>
    </b-alert>
    <b-alert variant="danger" show>
      - 전화번호 조회시 "-" 기호를 를 꼭 넣어주시고 조회가 안될 경우 "-" 기호를 뺀 뒤 시도해 주세요.<br/>
      - 예시) 010-1234-1234 검색 -> 01012341234 검색
    </b-alert>
    <b-card>
      <small>검색 후 필터링</small>
      <b-form-input type="text" placeholder="전화번호, 이메일, 이름, 아이디, 닉네임, 개인통관고유번호" v-model="form.list.filter" @keyup="filterList()"></b-form-input>
    </b-card>

    <c-table :table-data="items.filteredList" :fields="fields.list" :perPage.sync="perPage" :isBusy="busy.list" :getMoreBusy="busy.listmore" :hasMore="hasMore.list"
             :caption="items.filteredList.length + ' 개'" @get-more="list(true)"></c-table>
  </div>
</template>
<style scoped>
</style>
<script>

export default {
  name: 'Privacy',
  title: '개인정보 유출내역',
  data() {
    return {
      form: {
        list: {
          q: '',
          filter: '',
        }
      },
      items: {list: [], filteredList: []},
      item: {list: {}},
      fields: {
        list: [
          {key: 'm_no', label: '고객번호', class: 'text-center'},
          {key: 'm_id', label: '고객 ID', class: 'text-center'},
          {key: 'name', label: '이름', class: 'text-center'},
          {key: 'nickname', label: '닉네임', class: 'text-center'},
          {key: 'email', label: '이메일', class: 'text-center'},
          {key: 'bl_pccc', label: '개인통관고유번호', style: 'width: 130px', class: 'text-center'},
          {key: 'regdt', label: '가입일시', class: 'text-center'},
          {key: 'html1', label: '전화번호<br/>휴대폰번호', class: 'text-center w-125px'},
          {key: 'html2', label: '유출내역'},
        ]
      },
      lastBody: {list: {}},
      busy: {list: false, addBrand: false},
      hasMore: {list: false},
      ac: {list: null}, // abortController
      perPage: 40,
      abortController: null,
    }
  },
  async created() {
  },
  async beforeDestroy() {
    if (this.abortController) this.abortController.abort();
  },
  methods: {
    async list(more) {
      if (!this.form.list.q) {
        return alert('검색어를 입력해주세요');
      }
      await this.$api.postTable(this, '/customer/privacy', {q: this.form.list.q}, {more, fnAssign: this.assignTableData});
      this.filterList();
    },
    assignTableData(e) {
      e.html1 = `${e.phone}<br/>${e.mobile}`;
      e.html2 = `${e.infoArr.length ? e.infoArr.join(', ') : '유출내역이 없습니다'}`;
      e._filter = 'm_id,name,nickname,email,bl_pccc,phone,mobile'.split(',').map(k => e[k]).join('\n');
    },
    filterList() {
      const s = this.form.list.filter.trim();
      if (!s) {
        this.items.filteredList = this.items.list;
      } else {
        const re = new RegExp(s.replace(/[/\\^$*+?.()|[\]{}]/g, '\\$&'), 'i'); // escape regexp
        this.items.filteredList = this.items.list.filter(e => {
          return re.test(e._filter);
        });
      }
    },
  }
}
</script>
